import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from 'ng-fullcalendar';
import { Http, HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { Options, Logger } from 'angular2-logger/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReCaptchaModule } from 'angular2-recaptcha';


import * as $ from 'jquery';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent  } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { BlogComponent } from './blog/blog.component';
import { FundraisingComponent } from './fundraising/fundraising.component';
import { EventsComponent } from './events/events.component';
import { ContentSesrvice } from './service/content.service';
import { EventSesrvice } from './service/event.service';
import { MissionComponent } from './about/mission.component';
import { AboutComponent } from './about/about-detail.component';
import { HomeNavigationComponent } from './navigation/home-navigation.component';
import { SiteNavigationComponent } from './navigation/site-navigation.component';
import { BlogHighlightsComponent } from './blog/blog-highlights.component';
import { BlogDetailComponent } from './blog/blog-detail.component';
import { FundraisingHomeComponent } from './fundraising/fundraising-home.component';
import { CareGiverComponent } from './resources/caregiver/caregiver.component';
import { DonorComponent } from './resources/donors/donor.component';
import { FactsComponent } from './resources/facts/facts.component';
import { GetInvolvedComponent } from './resources/getinvolved/getinvolved.component';
import { HeartComponent } from './resources/heart/heart.component';
import { KidneyComponent } from './resources/kidney/kidney.component';
import { LiverComponent } from './resources/liver/liver.component';
import { LungComponent } from './resources/lung/lungs.component';
import { organDonorComponent } from './resources/organdonor/organdonor.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PartnersComponent } from './partners/partners.component';
import { AssistanceForm } from './contact/assistance.component';
import { ThankyouComponent } from './donation/thankyou/thankyou.component';
import { SponsorService } from './service/sponsor.service';
import { SponsorComponent } from './sponsors/sponsor.component';
import { CampaignComponent } from './campaign/campaign.component';
import { CampaignDetailComponent } from './campaign/campaigndetail.component';
import { CampaignService } from './service/Campaign.service';
import { ImpactComponent } from './impact/impact.component';
import { ImpactDetailComponent } from './impact/impact-detail.component';
import { DonorsComponent } from './donors/donors.component';




@NgModule({
  declarations: [
    AppComponent,
    HomeNavigationComponent,
    BlogComponent,
    BlogHighlightsComponent,
    BlogDetailComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    AboutComponent,
    FundraisingHomeComponent,
    FundraisingComponent,
    ThankyouComponent,
    EventsComponent,
    MissionComponent,
    SiteNavigationComponent,
    CareGiverComponent,
    DonorComponent,
    FactsComponent,
    GetInvolvedComponent,
    HeartComponent,
    KidneyComponent,
    LiverComponent,
    LungComponent,
    organDonorComponent,
    PrivacyComponent,
    PartnersComponent,
    AssistanceForm,
    SponsorComponent,
    CampaignComponent,
    CampaignDetailComponent,
    ImpactComponent,
    ImpactDetailComponent,
    DonorsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, 
    NgbModule.forRoot(),
    AppRoutingModule,
    FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReCaptchaModule
  ],
  providers: [ 
    ContentSesrvice,
    EventSesrvice,
    SponsorService,
    CampaignService
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }


