import { Component } from '@angular/core';


@Component({
  selector: 'app-kidney',
  templateUrl: './kidney.component.html',
})

export class KidneyComponent  {

  loading = true;


  constructor() {
    this.loading = false;
  }

  

}


    