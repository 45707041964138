import { Component, OnInit } from '@angular/core';
import { ContentSesrvice, Content } from '../service/content.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-about-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./about.component.css'],
})

export class MissionComponent implements OnInit {

  loadingMission = true;
  missionStatement: Content;
  loading = false;

  constructor(private contentSesrvice: ContentSesrvice) {

  }
  
  ngOnInit() {   
    
    this.loadingMission = false;
    }
}
