import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html'
})

export class PrivacyComponent  {

  
 
  constructor() {
   
   }

  
}


    