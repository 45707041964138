import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentSesrvice, Content } from '../service/content.service';


@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog.component.css'],
})

export class BlogDetailComponent implements OnInit {

  loading = true;
  blogEntry: Content = this.contentService.emptyContent();

  ngOnInit() {
    this.loading = true;
    this.activeRoute.params.subscribe(params => {
      
      this.contentService.getContent('blog').then(b => {
        const title = this.contentService.buildTitleFromUrl(params['id'] || '');
        this.blogEntry = b.find(x => x.title.toLowerCase() === title.toLowerCase());
        this.loading = false;
      })
    });
  }


  constructor(private activeRoute: ActivatedRoute,
    private router: Router,
    private contentService: ContentSesrvice) {

    

  }


}
 