import { Component } from '@angular/core';


@Component({
  selector: 'app-donor',
  templateUrl: './donor.component.html',
})

export class DonorComponent  {

  loading = true;


  constructor() {
    this.loading = false;
  }

  

}


    