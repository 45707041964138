import { Component, OnInit } from '@angular/core';
import { ContentSesrvice, Content } from '../service/content.service';



@Component({
  selector: 'app-fundraising-home',
  templateUrl: './fundraising-home.component.html',
  styleUrls: ['./fundraising.component.css'],
})

export class FundraisingHomeComponent implements OnInit {

  loading = true;
  content: Content;


  ngOnInit() {
    this.loading = true;
    this.contentService.getContent('fundraisinghome').then(b => {
      this.content = b[0];
        this.loading = false;
    });

  }


  constructor(private contentService: ContentSesrvice) {


  }


}
 

