import { Component, OnInit } from '@angular/core';
import { ContentSesrvice, Content } from '../service/content.service';



@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css'],
})

export class PartnersComponent implements OnInit {

  loading = false;
 

  ngOnInit() { 
    
      
  }


  constructor(private contentService: ContentSesrvice) {


  }


}
 

