import { Component, OnInit } from '@angular/core';
import { ContentSesrvice, Content } from '../service/content.service';


@Component({
  selector: 'app-blog-highlights',
  templateUrl: './blog-highlights.component.html',
  styleUrls: ['./blog.component.css'],
})

export class BlogHighlightsComponent implements OnInit {

  loading = true;
  blogs: Array<Content> = [];
  

  ngOnInit() { 

    let count = 0;
    this.blogs = [];
    this.contentService.getContent('blog').then(b => {
      b.forEach(p => {
        if (count < 4) {
          this.blogs.push(p);
          count = count + 1;
        }
      });
      this.loading = false;
    });

    
      
  }


  constructor(private contentService: ContentSesrvice) {
    

  }

  getUrl(c: Content) {
    return this.contentService.makeUrl(c);
  }

}
 

