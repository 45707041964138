import { Component, OnInit } from '@angular/core';
import { ContentSesrvice, Content  } from '../service/content.service';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
})

export class BlogComponent implements OnInit {

  loading = false;
  blogs: Array<Content> = [];
  

  ngOnInit() { 
    this.contentService.getContent('blog').then(b => {
      this.blogs = b;
      this.loading = false;
    }).then(() => {
      this.loading = false;
    });   
    
    
  }


constructor(private contentService: ContentSesrvice) {


}

  getUrl(c: Content) {
    return this.contentService.makeUrl(c);
  }

}
 

