import { Component } from '@angular/core';


@Component({
  selector: 'app-organ',
  templateUrl: './organdonor.component.html',
})

export class organDonorComponent  {

  loading = true;
 
 
  constructor() {
    this.loading = false;
   }

  

}


    