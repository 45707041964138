import { Component, OnInit, ViewChild } from '@angular/core';
import { Content, ContentSesrvice } from '../service/content.service';


@Component({
  selector: 'app-donors',
  templateUrl: './donors.component.html'
})

export class DonorsComponent implements OnInit {

  loading = true;
  blogEntry: Content = this.contentService.emptyContent();

  ngOnInit() {
    this.loading = true;
    
    this.contentService.getContent('thanksdonors').then(b => {
      console.log('--------->');
      console.log(b);
      
      const title = 'Thanks to donors like you:';
      this.blogEntry = b.find(x => x.title.toLowerCase() === title.toLowerCase());
      this.loading = false;
      
      console.log(this.blogEntry);
      console.log('--------->');
    });
    
  }


  constructor(private contentService: ContentSesrvice) {



  }


}


    