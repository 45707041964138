import { Inject, Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Http, Request, Headers, Response } from '@angular/http';
import { Logger } from 'angular2-logger/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { C } from '@angular/core/src/render3';


export interface CampaignEntry {
    transactionDateTime: Date;
    description: string;
    amount: number;
}

export interface Campaign {
    id: number;
    recipient: string
    transplantType: string;
    identifier: string;
    campaignStatus: string;
    campaignHtml: string;
    campaignimage: string;
    campaignlocation: string;
    fundraisedAmount: number;
    requestedAmount: number;
    showRibbon: string;
    campaignData: Array<CampaignEntry>;
}




export interface PaymentInfo {
    name: string,
    displayname: string,
    email: string,
    number: number,
    donation: number,
    exp_month: number,
    exp_year: number,
    cvc: number,
    address_zip: string,
    message: string,
    stripeToken: string,
    campaignid: number,
    campaignname: string,
    anonymous: string
}

export interface StripeAuth {
    name: string,
    number: number,
    exp_month: number,
    exp_year: number,
    cvc: number,
    address_zip: string,
}




@Injectable()
export class CampaignService {

    constructor(private http: HttpClient) {

    }

    campaigns = new ReplaySubject<Array<Campaign>>(null);

    emptyCampaign() {
        return {
            id: 0,
            recipient: '',
            transplantType: '',
            identifier: '',
            campaignStatus: '',
            campaignHtml: '',
            campaignimage: '',
            campaignlocation: '',
            showRibbon: 'No',
            fundraisedAmount: 0,
            requestedAmount: 0,
            campaignData: []
        };
    }

    emptyCampaignEntry() {
        return {
            transactionDateTime: new Date,
            description: '',
            amount: 0
        }
    }



    fetchactivecampaigns() {
        //let promise = new Promise<Array<Campaign>>((resolve, reject) => {
            this.http.get<Array<Campaign>>(`/api/activecampaigns`).subscribe(x => {
                //resolve(x);
                this.campaigns.next(x);
                console.log(x);
            },
                error => {
                    console.error('Error extracting compaigns');
                    console.error(error);
                    this.campaigns.next(null);
                });
        //});
        //return promise;
    }
}