import { Component, OnInit } from '@angular/core';
import { ContentSesrvice, Content } from '../service/content.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-about-detail',
  templateUrl: './about-detail.component.html',
  styleUrls: ['./about.component.css'],
})

export class AboutComponent implements OnInit {

  loading = true;
  leadership: Array<Content> = [];
  assistance: Content;

  constructor(private contentSesrvice: ContentSesrvice) {

  }
  
  ngOnInit() {   
    this.loading = false;   
    }
}
