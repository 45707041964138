import { Component } from '@angular/core';


@Component({
  selector: 'app-lung',
  templateUrl: './lungs.component.html',
})

export class LungComponent  {

  loading = true;


  constructor() {
    this.loading = false;
  }

  

}


    