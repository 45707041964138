import { Component, OnInit } from '@angular/core';
import { ContentSesrvice, Content } from '../service/content.service';



@Component({
  selector: 'app-fundraising',
  templateUrl: './fundraising.component.html',
  styleUrls: ['./fundraising.component.css'],
})

export class FundraisingComponent implements OnInit {

  loading = true;
  topcontent: Content;
  assistance: Content;
  contentData: Array<Content> = [];
  

  ngOnInit() { 
    this.contentService.getContent('fundraisingpage').then(b => {
      this.topcontent = b.find(x => x.id === 21);
      this.assistance = b.find(x => x.id === 22);
      this.contentData = b.filter(x => x.id === 21 || x.id === 22);
      this.loading = false;
    });
  }


  constructor(private contentService: ContentSesrvice) {


  }


}
 

