import { Component } from '@angular/core';


@Component({
  selector: 'app-caregiver',
  templateUrl: './caregiver.component.html',
})

export class CareGiverComponent {

  loading = true;


  constructor() {
    this.loading = false;
  }

  
   


}