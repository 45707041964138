import { Component, OnInit, ViewChild } from '@angular/core';
import { zip } from 'rxjs';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css'],
})

export class ThankyouComponent implements OnInit {

  loading = false;
 
  constructor() {
   
   }

  
    ngOnInit() { 
    }
}


    