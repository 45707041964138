import { Component, OnInit } from '@angular/core';
import { CampaignService, Campaign, CampaignEntry } from '../service/Campaign.service';


@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css'],
})

export class CampaignComponent implements OnInit {

  loading = true;
  campaigns: Array<Campaign> = [];

  ngOnInit() { 
    this.loading = true;
    this.campaignService.fetchactivecampaigns();
    this.campaignService.campaigns.subscribe(c => {
      this.campaigns = c;    
      this.loading = false;
    });
      
  }


  constructor(private campaignService: CampaignService) { }


}
 

