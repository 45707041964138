import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ContentSesrvice, Content } from '../service/content.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [NgbCarouselConfig] // add NgbCarouselConfig to the component providers
})

export class HomeComponent implements OnInit {

  loading = true;

  

  ngOnInit() { 
     
    
      
    this.loading = false;
  }
  constructor(private contentSesrvice: ContentSesrvice) {
    
  }

  
 

}
