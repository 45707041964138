import { Inject, Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Http, Request, Headers, Response } from '@angular/http';
import { Logger } from 'angular2-logger/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



export interface Content
{
    id: number,
    type: string,
    title: string,
    description: string,
    fulleStory: string,
    imagelink: string,
    postDate: Date
}



@Injectable()
export class ContentSesrvice {

    constructor(private http: HttpClient) {
        
    }

    blogData = new BehaviorSubject(null);
    leadershipData = new BehaviorSubject(null);
    impactData = new BehaviorSubject(null);

    emptyContent() {
        return {
            id: 0,
            type: "",
            title: "",
            description: "",
            fulleStory: "",
            imagelink: "",
            postDate: new Date
        };
    }

    makeUrl(c: Content) {

        switch (c.type)
        {
            case "blog":
                return `/blog/${c.title.replace(/ /gi, '-')}`;
            
            case "Impact":
                return `/impact/${c.title.replace(/ /gi, '-')}`;
        }
        
    }

    buildTitleFromUrl(t: string) {
        return `${t.replace(/-/gi, ' ')}`;
    }

    public getContent(t: string)  {
        let promise = new Promise<Array<Content>>((resolve, reject) => {
            
            // var hasData = Array<Content>();

            // switch (t) {
            //     case "blog":
            //         this.blogData.subscribe(data => hasData);
            //         break;
            //     case "Impact":
            //         this.blogData.subscribe(data => hasData);
            //         break;
            //     case "leadership":
            //         this.leadershipData.subscribe(data => hasData);
            //         break;
            // }

            // // if (t === 'leadership') {
            // //     this.leadershipData.subscribe(data => hasData);
            // // }
            // // else if (t === 'blog') {
            // //     this.blogData.subscribe(data => hasData);
            // // }

            // if (hasData && hasData.length > 0) {
            //     resolve(hasData);
            // }
            // else {
               
                this.http.get<Array<Content>>(`/api/content/${t}`).subscribe(x => {
                    resolve(x);
                },
                    error => {
                        console.error('Error extracting content');
                        console.error(error);
                        reject({
                            id: 0,
                            type: 'Error',
                            title: 'Error loading...',
                            description: 'Error loading...',
                            fulleStory: 'Error loading...',
                            imagelink: '',
                        });
                    });
            //}
        });

        return promise;
    }

}
