import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentSesrvice, Content } from '../service/content.service';


@Component({
  selector: 'app-impact-detail',
  templateUrl: './impact-detail.component.html',
  styleUrls: ['./impact.component.css'],
})
export class ImpactDetailComponent implements OnInit {

  loading = true;
  blogEntry: Content

  ngOnInit() { 
    
    this.activeRoute.params.subscribe(params => {
      const title = this.contentService.buildTitleFromUrl(params['id'] || '');
      this.contentService.getContent('Impact').then(b => {        
        console.log(title.toLowerCase().trim());
        this.blogEntry = b.find(x => x.title.toLowerCase().trim() === title.toLowerCase().trim());
        this.loading = false;
      });

    });
      
  }


  constructor(private activeRoute: ActivatedRoute,
    private router: Router,
    private contentService: ContentSesrvice) {

    

  }


}
 

