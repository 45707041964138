import { Inject, Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Http, Request, Headers, Response } from '@angular/http';
import { Logger } from 'angular2-logger/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



export interface CalendarEvent
{
    id: number,
    title: string,
    briefDescription: string,
    url: string,
    start: Date,
    end: Date,
    allDay: boolean,
    editable: boolean,
    backgroundColor: string,
    borderColor: string,
    imagelink: string
}

export interface TAEvent {
    id: number,
    title: string,
    briefDescription: string,
    description: string,
    locaiton: string,
    locationMap: string,
    startDateTime: Date,
    endDateTIme: Date,
    site: string,
    delete: string,
    vendorInfo: string,
    eventPrice?: number,
    vendorPrice: number,
    maxVendors?: number,
    maxAttendees?: number,
    eventType: number,
    childPrice: number,
    question1: string,
    question2: string,
    recurring?: number
    showAttendBtn: string,
    imagelink: string

}


@Injectable()
export class EventSesrvice {

    constructor(private http: HttpClient) {
        this.getCalendarEvents();
        this.getVersionInfo();
    }

    calendarEvents = new ReplaySubject < Array<CalendarEvent>>(null);
    futureEvents = new ReplaySubject<Array<TAEvent>>(null);
    release = new ReplaySubject<string>(null);

    public getCalendarEvents() {
        this.http.get<Array<any>>(`/api/events/calendar`).subscribe(x => {
            console.log(x);
            this.calendarEvents.next(x);
          },
          error => {
            console.log('Error extracting events')
          });
    }

    public getFutureEvents() {
        var promise = new Promise<Array<CalendarEvent>>((resolve, reject) => {
            this.http.get<Array<CalendarEvent>>(`/api/events/future`).subscribe(x => {
                console.log(x);
                resolve(x);
            },
                err => {
                    console.log(err.error);
                    reject(err.error);
                });
        });

        return promise;    
        
    }

    getVersionInfo() {
      this.http.get<any>(`/api/version`).subscribe(x => {
        console.log('App Version', x);
        this.release.next(x.release);
      },
        error => {
          console.log('Error extracting app version');
          console.error(error);
        });
    }

}
