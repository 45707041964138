import { Component } from '@angular/core';


@Component({
  selector: 'app-get-involved',
  templateUrl: './getinvolved.component.html',
})

export class GetInvolvedComponent  {

  loading = true;


  constructor() {
    this.loading = false;
  }
  

}


    