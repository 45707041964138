import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isRegExp } from 'util';
import { CampaignService, Campaign, CampaignEntry, PaymentInfo, StripeAuth } from '../service/Campaign.service';


@Component({
  selector: 'app-campaign-detail',
  templateUrl: './campaigndetail.component.html',
  styleUrls: ['./campaign.component.css'],
})

export class CampaignDetailComponent implements OnInit {

  loading = true;
  id: string = '';
  selectedCampaign: Campaign = this.campaignService.emptyCampaign();
  showForm: boolean = false;
  ribbonurl = '';
  showThankyou: boolean = false;
  isSubmitting: boolean = false;
  errmessage: string = '';

  paymentInfo: PaymentInfo = {
                  name: '',
                  displayname: '',
                  email: '',
                  number: 0,
                  donation: 0,
                  exp_month: 0,
                  exp_year: 0,
                  cvc: 0,
                  address_zip: '',
                  message: '',
                  stripeToken: '',
                  campaignid: 0,
                  campaignname: '',
                  anonymous: 'N'
                };

  ngOnInit() { 
    this.loading = true;
    this.campaignService.fetchactivecampaigns();

    this.route.params.subscribe(params => {
      this.id = params["id"] || "";
    });

    this.campaignService.campaigns.subscribe(c => {
      if (c && Number(this.id) > 0) {
        this.selectedCampaign = c.find(x => x.id === Number(this.id));
        this.buildRibbonUrl();
        this.paymentInfo = {
          name: '',
          displayname: '',
          email: '',
          number: 0,
          donation: 0,
          exp_month: 0,
          exp_year: 0,
          cvc: 0,
          address_zip: '',
          message: '',
          stripeToken: '',
          campaignid: this.selectedCampaign.id,
          campaignname: this.selectedCampaign.identifier,
          anonymous: 'N'
        };
        
        

        this.loading = false;
        this.showForm = false;
      }
    });
      
  }


  constructor(private campaignService: CampaignService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private router: Router) {


  }

  donateBtnHandler() {
    this.showForm = true;
  }

  buildRibbonUrl() {
    const val = Number((this.selectedCampaign.fundraisedAmount / this.selectedCampaign.requestedAmount) * 100);
    console.log(val);
    if (val >= 5 && val < 10) {
      this.ribbonurl = '/assets/img/ribbon/ribbon5.png';
    }
    else if (val >= 10 && val < 15) {
      this.ribbonurl = '/assets/img/ribbon/ribbon10.png';
    }
    else if (val >= 15 && val < 20) {
      this.ribbonurl = '/assets/img/ribbon/ribbon15.png';
    }
    else if (val >= 20 && val < 25) {
      this.ribbonurl = '/assets/img/ribbon/ribbon20.png';
    }
    else if (val >= 25 && val < 30) {
      this.ribbonurl = '/assets/img/ribbon/ribbon25.png';
    }
    else if (val >= 30 && val < 35) {
      this.ribbonurl = '/assets/img/ribbon/ribbon30.png';
    }
    else if (val >= 35 && val < 40) {
      this.ribbonurl = '/assets/img/ribbon/ribbon35.png';
    }
    else if (val >= 40 && val < 45) {
      this.ribbonurl = '/assets/img/ribbon/ribbon40.png';
    }
    else if (val >= 45 && val < 50) {
      this.ribbonurl = '/assets/img/ribbon/ribbon45.png';
    }
    else if (val >= 50 && val < 55) {
      this.ribbonurl = '/assets/img/ribbon/ribbon50.png';
    }
    else if (val >= 55 && val < 60) {
      this.ribbonurl = '/assets/img/ribbon/ribbon55.png';
    }
    else if (val >= 60 && val < 65) {
      this.ribbonurl = '/assets/img/ribbon/ribbon60.png';
    }
    else if (val >= 65 && val < 70) {
      this.ribbonurl = '/assets/img/ribbon/ribbon65.png';
    }
    else if (val >= 70 && val < 75) {
      this.ribbonurl = '/assets/img/ribbon/ribbon70.png';
    }
    else if (val >= 75 && val < 80) {
      this.ribbonurl = '/assets/img/ribbon/ribbon75.png';
    }
    else if (val >= 80 && val < 85) {
      this.ribbonurl = '/assets/img/ribbon/ribbon80.png';
    }
    else if (val >= 85 && val < 90) {
      this.ribbonurl = '/assets/img/ribbon/ribbon85.png';
    }
    else if (val >= 90 && val < 95) {
      this.ribbonurl = '/assets/img/ribbon/ribbon90.png';
    }
    else if (val >= 95 && val < 100) {
      this.ribbonurl = '/assets/img/ribbon/ribbon95.png';
    }
    else if (val >= 100) {
      this.ribbonurl = '/assets/img/ribbon/ribbon100.png';
    }
    else {
      this.ribbonurl = '/assets/img/ribbon/ribbon0.png';
    }
    
  }

  submitHandler() {
    console.log(this.paymentInfo);
    this.errmessage = "";
    let formtest = '<ul>';
    if (this.paymentInfo.name.length < 2) {
      formtest = formtest + '<li>Name on Credit Card Required</li>';
    }    
    if (this.paymentInfo.number.toString().length < 6) {
      formtest = formtest + '<li>Credit Card Number Required</li>';
    }
    if (this.paymentInfo.cvc < 1 || this.paymentInfo.cvc > 999) {
      formtest = formtest + '<li>Credit Card CVC Required</li>';
    }
    if (this.paymentInfo.displayname === '') {
      this.paymentInfo.displayname = this.paymentInfo.name;      
    }
    if (this.paymentInfo.email.length < 8) {
      formtest = formtest + '<li>Email is Required</li>';
    }
    if (this.paymentInfo.donation < 1) {
      formtest = formtest + '<li>Donation Amount is Required</li>';
    }
    if (this.paymentInfo.address_zip.length < 5) {
      formtest = formtest + '<li>Billing Zip Code is Required</li>';
    }
    if (this.paymentInfo.exp_month < 1 || this.paymentInfo.exp_month > 12) {
      formtest = formtest + '<li>Expiration Month Invalid</li>';
    }
    if (this.paymentInfo.exp_year < 22) {
      formtest = formtest + '<li>Expiration Year Invalid</li>';
    }

    let okToContinue = false;
    if (formtest === '<ul>') {
      okToContinue = true;

      if (this.paymentInfo.donation > 5000) {
        if (!confirm("Your donation is over $5,000. Are you sure?")) {
          okToContinue = false;
          this.errmessage = "Please adjust your donation amount;"
        }
      }
      
      
    }  
    else {
      this.errmessage = formtest + '</ul>';
    }

    
    
    if (okToContinue) {
      this.isSubmitting = true;

      const stripeCall: StripeAuth = {
        name: this.paymentInfo.name,
        number: this.paymentInfo.number,
        exp_month: this.paymentInfo.exp_month,
        exp_year: this.paymentInfo.exp_year,
        cvc: this.paymentInfo.cvc,
        address_zip: this.paymentInfo.address_zip,
      };

      (<any>window).Stripe.card.createToken(stripeCall, (status: number, response: any) => { 
        console.log(status);
        console.log(response);
        if (status === 200) {
          // Card Accepted

          const donation = {
            Campaignid: this.paymentInfo.campaignid,
            StripeToken: response.id,
            Name: this.paymentInfo.name,
            DisplayName: this.paymentInfo.displayname,
            Email: this.paymentInfo.email,
            DonationAmt: this.paymentInfo.donation,
            Message: this.paymentInfo.message,
            Anonymous: this.paymentInfo.anonymous
          };

          this.http.post(`/api/campaign`, donation).subscribe(x => {
            this.showThankyou = true;
            this.showForm = false;
            console.log(x);
            this.cd.detectChanges();
            this.isSubmitting = false;
          },
            err => {
              this.isSubmitting = false;
              console.log(err.error);
              this.errmessage = err.error + "<br/> Please refresh this page to try your credit card or a different credit card again!!!";
              this.cd.detectChanges();
              this.showThankyou = false;
            });
        } 
        else {
          // Card NOT Accepted
          this.showThankyou = false;
          this.isSubmitting = false;
          console.log('Response:', response);
          console.log('Status', status);
          this.errmessage = "There was a problem with your card. Transaction aborted <br/>" + response.error.message;
          this.cd.detectChanges();
        }
      });
        
    }

  }

}
 

