

import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about-detail.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailComponent } from './blog/blog-detail.component';
import { FundraisingComponent } from './fundraising/fundraising.component';
import { EventsComponent } from './events/events.component';
import { CareGiverComponent } from './resources/caregiver/caregiver.component';
import { DonorComponent } from './resources/donors/donor.component';
import { FactsComponent } from './resources/facts/facts.component';
import { GetInvolvedComponent } from './resources/getinvolved/getinvolved.component';
import { HeartComponent } from './resources/heart/heart.component';
import { KidneyComponent } from './resources/kidney/kidney.component';
import { LiverComponent } from './resources/liver/liver.component';
import { LungComponent } from './resources/lung/lungs.component';
import { organDonorComponent } from './resources/organdonor/organdonor.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ThankyouComponent } from './donation/thankyou/thankyou.component';
import { CampaignComponent } from './campaign/campaign.component';
import { CampaignDetailComponent } from './campaign/campaigndetail.component';
import { ImpactComponent } from './impact/impact.component';
import { ImpactDetailComponent } from './impact/impact-detail.component';
import { DonorsComponent } from './donors/donors.component';


//import { AuthGuard } from './shared/auth/auth-guard.service';

const appRoutes: Routes = [
  
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "home", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "blog", component: BlogComponent },
  { path: "blog/:id", component: BlogDetailComponent },
  { path: "contact", component: ContactComponent },
  { path: "fundraising", component: FundraisingComponent },
  { path: "campaigns", component: CampaignComponent },
  { path: "campaign/:id", component: CampaignDetailComponent },
  { path: "impact", component: ImpactComponent },
  { path: "impact/:id", component: ImpactDetailComponent },  
  { path: "events", component: EventsComponent },
  { path: "donation/thankyou", component: ThankyouComponent },
  { path: "resources/caregiver", component: CareGiverComponent },
  { path: "resources/donors", component: DonorComponent },
  { path: "resources/facts", component: FactsComponent },
  { path: "resources/getinvolved", component: GetInvolvedComponent },
  { path: "resources/heart", component: HeartComponent },
  { path: "resources/kidney", component: KidneyComponent  },
  { path: "resources/liver", component: LiverComponent },
  { path: "resources/lung", component: LungComponent },
  { path: "resources/whybeanorgandonor", component: organDonorComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "thankyou", component: DonorsComponent },
  
  
];


@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { enableTracing: true, errorHandler: console.error, useHash: false })],
    exports: [RouterModule]
  })
  export class AppRoutingModule {
  
  }