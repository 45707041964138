import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventSesrvice } from '../service/event.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent  {
  
  date: Date;
  build = "";
  selectedState = "";
  currentDate: Date;

  ngOnInit() { 
    this.date = new Date();
    console.log(this.date);
  }

  constructor(private router: Router,
    protected eventService: EventSesrvice  ) {
    this.eventService.release.subscribe(r => {
      console.log(r);
      this.build = r;
    });
  }

  

}
