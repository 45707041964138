import { Component, OnInit, ViewChild } from '@angular/core';
import { sponsor, SponsorService } from '../service/sponsor.service';


@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html'
})

export class SponsorComponent implements OnInit {

  sponsors: Array<sponsor> = [];
 
  constructor(private sponsorService: SponsorService) {
    this.sponsorService.getCorporateSponsors();
  }
  
  ngOnInit() {
    this.loadSponsors();
  }

  private loadSponsors() {
    this.sponsorService.corporateSponsors.subscribe(x => {
      this.sponsors = x;
      
    });
  }
}


    