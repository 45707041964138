import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarComponent } from 'ng-fullcalendar';
import { Options } from 'fullcalendar';

import { EventSesrvice, CalendarEvent } from 'src/app/service/event.service';
import { zip } from 'rxjs';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
})

export class EventsComponent implements OnInit {

  loading = false;
  events: Array<CalendarEvent> = [];
  displayEvent: any;
 
  constructor(protected eventService: EventSesrvice) {
   
   }

  
    ngOnInit() { 
      
      this.loading = true;
      this.loadEvents();
      

    }

    
    clearEvents() {
      this.events = [];
    }

  loadEvents() {
    
      this.loading = true;
    this.eventService.getFutureEvents().then(data => {
      //* ngIf="item.title.indexOf('Friday') === -1 && item.title.indexOf('Wednesday') === -1 && item.title.indexOf('Sunday') === -1 "
      this.events = data;        
        this.loading = false;      
        console.log(data);
      });
    }
    

  clickButton(model: any) {
    this.displayEvent = model;
  }
  
  eventClick(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        end: model.event.end,
        title: model.event.title,
        allDay: model.event.allDay
        // other params
      },
      duration: {}
    }
    this.displayEvent = model;
  }
  updateEvent(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        end: model.event.end,
        title: model.event.title
        // other params
      },
      duration: {
        _data: model.duration._data
      }
    }
    this.displayEvent = model;
  }

  showbg(item: any) {
    if (item.imagelink && item.imagelink !== '') {
      return item.imagelink;
    }
    return '/assets/img/bingoboards.jpg';
  }
}


    