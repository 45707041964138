import { Component } from '@angular/core';


@Component({
  selector: 'app-liver',
  templateUrl: './liver.component.html',
})

export class LiverComponent  {

  loading = true;


  constructor() {
    this.loading = false;
  }

  

}


    