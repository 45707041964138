import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-home-navbar',
  templateUrl: './home-navigation.component.html',
  styleUrls: ['./navigation.component.css']
})

export class HomeNavigationComponent implements OnInit {
  

  onNavigationSelection = new EventEmitter<string>();
  homeClass = '';
  aboutClass = '';
  contactClass = '';
  resourceClass = '';

  constructor(private router: Router) {
    
    this.router.events
    .subscribe((event) => {
      this.homeClass = '';
      this.aboutClass = '';
      this.contactClass = '';
      this.resourceClass = '';
      
      if (this.router.url === '/home') {
        this.homeClass = 'active';   
      } else if (this.router.url === '/about') {      
        this.aboutClass = 'active';
      } else if (this.router.url === '/contact') {      
        this.contactClass = 'active';
      } else {
        this.resourceClass = 'active';
      }

    });
  }

  ngOnInit() {
    
  }

  navigationHandler(p: string) {
    this.homeClass = '';
    this.aboutClass = '';
    this.contactClass = '';

    if(p === 'home') {
      this.homeClass = 'active';      
    } else if (p === 'about') {
      this.aboutClass = 'active';
    } else if (p === 'contact') {
      this.contactClass = 'active';
    } 

    console.log(this.router);
  }
}

